<template>
  <div>
    <div style="height: 4px">
      <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
    </div>

    <div v-if="!bannerId && !disabled">
      <v-btn
      
        small
        color="primary lighten-1"
        @click="createBannerPopup"
      >
        {{ 'action.create'|t }}
      </v-btn>
    </div>


    <div v-if="banner">
      <bannerModel
        @removeBanner="removeBanner"
        @updateBanner="updateBanner"
        :updateBannerRequest="updateBannerRequest"
        :banner="banner"
        :disabled="disabled"
        :formDataItem="formDataItem"
        class="mb-4"
      ></bannerModel>

      <div class="mb-2 font-weight-light primary--text body-2">
        <div
          v-if="recommendSizeHelp"
          class="mb-2"
        >
          {{recommendSizeHelp}}
        </div>

        <div
          v-if="!disabledFullscreenOption"
          class="mb-2"
        >
          {{'banner.fullscreen.recommend_size.help'| t}}
        </div>

        <div class="mb-2">
          {{'banner.photo.help'| t}}
        </div>
      </div>

      <bannerItems
        @refresh="loadBanner"
        @updateItems="updateItems"
        :updateBannerRequest="updateBannerRequest"
        :banner="banner"
        :disabled="disabled"
      ></bannerItems>
    </div>
  </div>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    formDataItem: {
      type: Object,
    },
    value: {
      type: String,
      default: null,
    },
    simpleMode: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    bannerId: null,
    banner: null,
    loading: false,
  }),
  methods: {
    createBannerPopup() {
      this.$apopup.prompt({
        title: `${this.$t('action.create')}${this.$t('data.banner')}`,
        promptPlaceholder: this.$t('banner.data.name'),
        promptMaxLength: 50,
        applyCallback: this.createBanner,
      })
    },
    async createBanner(data) {
      this.loading = true
      try {
        const result = await this.$api.collection.bannerApi.create({ name: data })
        this.bannerId = result.id
      } catch (error) {
        console.warn(error)
      } finally {
        this.loading = false
      }
    },
    removeBanner() {
      this.bannerId = null
      this.banner = null
    },
    updateBanner(banner) {
      this.banner = banner
    },
    async loadBanner(quiet = false) {
      if(!this.bannerId) {
        this.banner = null
        return
      }

      if(!quiet) {
        this.loading = true
      }
      try {
        this.banner = await this.$api.collection.bannerApi.read(this.bannerId)
      } catch (error) {
        console.warn(error)
      } finally {
        this.loading = false
      }
    },
    updateItems(items) {
      this.$set(this.banner, 'items', items)
    },
    async updateBannerRequest(quiet = true) {
      try {
        await this.$api.collection.bannerApi.update(this.banner.id, this.banner)
        if(!quiet) {
          this.$snotify.info(null, `${this.$t('data.banner')}${this.$t('save.successfully')}`)
        }
      } catch (error) {
        console.warn(error)
        this.$snotify.error(
          this.$t('error.unexpect'),
          this.$t('save.failure')
        )
        throw error
      } finally {
        this.loadBanner(quiet)
      }
    },
  },
  computed: {
    disabledFullscreenOption() {
      return this.formDataItem.disabledFullscreenOption === true
    },
    recommendSize() {
      return this.formDataItem.recommendSize
    },
    recommendSizeHelp() {
      if(!this.recommendSize) return null
      return this.$t('banner.recommend_size.help', {
        width: this.recommendSize.width,
        height: this.recommendSize.height,
      })
    },
  },
  watch: {
    bannerId: {
      handler() {
        this.$emit('input', this.bannerId)
        this.$emit('change', this.bannerId)
        this.loadBanner()
      },
    },
    value: {
      immediate: true,
      handler() {
        this.bannerId = this.$eagleLodash.cloneDeep(this.value)
      },
    },
  },
  components: {
    bannerModel: () => import('./bannerModel.vue'),
    bannerItems: () => import('./bannerItems.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
